export class Language {
	code: string;
	localizedName: string;
	name: string;
}

export class LocaleData {
	languages: Language[];
}

export class AppSettings {
	public elementFirstTimeInstallerITero: string;
	public elementFirstTimeInstaller5D: string;
	public imageClsIteroElementRTH: string;
	public imageClsIteroFlexRTH: string;
	public imageClsIteroElement5D: string;
	public imageClsIteroFlex5D: string;
}

export class AppConfig {
	constructor(
		public appSettings: AppSettings = new AppSettings(),
		public localeData: LocaleData = new LocaleData()
	) {}
}

